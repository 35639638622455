// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Api from "../../../api/Api.res.js";
import * as Url from "../../../utils/Url.res.js";
import * as User from "../../../models/User.res.js";
import * as Hooks from "../../../libs/Hooks.res.js";
import * as React from "react";
import * as Button from "../../../styleguide/components/Button/Button.res.js";
import * as $$Promise from "../../../bindings/Promise.res.js";
import * as Container from "../../../styleguide/components/Container/Container.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Reservation from "../../../models/Reservation.res.js";
import * as SentryLogger from "../../../loggers/SentryLogger.res.js";
import * as ColocationProduct from "../../../models/ColocationProduct.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Routes_Marketplace from "../../../routes/common/Routes_Marketplace.res.js";
import * as ProductReservationData from "./ProductReservationData.res.js";
import * as ProductReservationForm from "./ProductReservationForm.res.js";
import * as ProductDetailsForSidebar from "../../product-detail/product-details-for-sidebar/ProductDetailsForSidebar.res.js";
import * as ProductReservationScss from "../ProductReservation.scss";

var css = ProductReservationScss;

function ProductCreateReservation$ProductCreateReservation(props) {
  var reservation = props.reservation;
  var product = props.product;
  var initialState = React.useMemo((function () {
          return {
                  status: "Editing",
                  input: reservation !== undefined ? ProductReservationData.Input.fromReservationDraft(reservation) : ProductReservationData.Input.empty(),
                  results: undefined,
                  submissionError: false
                };
        }), []);
  var match = Hooks.useReducer(initialState, (function (state, action) {
          if (typeof action !== "object") {
            switch (action) {
              case "Validate" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            input: state.input,
                            results: ProductReservationData.Validate.all(state.input),
                            submissionError: state.submissionError
                          }
                        };
              case "Submit" :
                  var match = state.status;
                  if (match === "Editing") {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              status: state.status,
                              input: state.input,
                              results: ProductReservationData.Validate.all(state.input),
                              submissionError: state.submissionError
                            },
                            _1: (function (param) {
                                if (ProductReservationData.Validate.valid(Belt_Option.getExn(param.state.results))) {
                                  return param.dispatch("CreateReservation");
                                }
                                
                              })
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "CreateReservation" :
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: "Submitting",
                            input: state.input,
                            results: state.results,
                            submissionError: state.submissionError
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              $$Promise.wait(Api.createProductReservation(product.id, param.state.input), (function (x) {
                                      if (x.TAG === "Ok") {
                                        return dispatch({
                                                    TAG: "GoToReserveConfirmation",
                                                    _0: x._0.id
                                                  });
                                      }
                                      SentryLogger.error1({
                                            rootModule: "ProductCreateReservation",
                                            subModulePath: {
                                              hd: "ProductCreateReservation",
                                              tl: /* [] */0
                                            },
                                            value: "make",
                                            fullPath: "ProductCreateReservation.ProductCreateReservation.make"
                                          }, "ProductCreateReservation::Error", [
                                            "Error",
                                            x._0
                                          ]);
                                      dispatch("FailSubmission");
                                    }));
                            })
                        };
              case "FailSubmission" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: "Editing",
                            input: state.input,
                            results: state.results,
                            submissionError: true
                          }
                        };
              
            }
          } else {
            if (action.TAG === "Update") {
              var input = action._0;
              var match$1 = state.status;
              if (match$1 !== "Editing") {
                return "NoUpdate";
              }
              var match$2 = state.results;
              if (match$2 !== undefined) {
                return {
                        TAG: "UpdateWithSideEffects",
                        _0: {
                          status: state.status,
                          input: input,
                          results: state.results,
                          submissionError: state.submissionError
                        },
                        _1: (function (param) {
                            param.dispatch("Validate");
                          })
                      };
              } else {
                return {
                        TAG: "Update",
                        _0: {
                          status: state.status,
                          input: input,
                          results: state.results,
                          submissionError: state.submissionError
                        }
                      };
              }
            }
            var id = action._0;
            return {
                    TAG: "SideEffects",
                    _0: (function (param) {
                        Url.visit(Routes_Marketplace.reserveConfirmation(id, product.id, product.seoCategory));
                      })
                  };
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.status;
  return JsxRuntime.jsxs(Container.make, {
              className: css.layout,
              children: [
                JsxRuntime.jsxs(ProductReservationForm.make, {
                      input: state.input,
                      results: state.results,
                      update: (function (input) {
                          dispatch({
                                TAG: "Update",
                                _0: input
                              });
                        }),
                      submit: (function (param) {
                          dispatch("Submit");
                        }),
                      providerName: product.providerName,
                      children: [
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(Button.make, {
                                    size: "LG",
                                    color: "Primary",
                                    expanded: true,
                                    busy: match$1 !== "Editing",
                                    submit: true,
                                    children: "Confirm"
                                  }),
                              className: css.buttonContainer
                            }),
                        state.submissionError ? "Something went wrong" : null
                      ]
                    }),
                JsxRuntime.jsx(ProductDetailsForSidebar.make, {
                      product: product,
                      className: css.productDetails,
                      toggleDetails: true,
                      userLoginStatus: props.userLoginStatus,
                      setUserData: (function (prim) {
                          
                        })
                    })
              ]
            });
}

var ProductCreateReservation = {
  css: css,
  Input: undefined,
  Validate: undefined,
  ValidationResult: undefined,
  make: ProductCreateReservation$ProductCreateReservation
};

function ProductCreateReservation$default(props) {
  var props$1 = props.props;
  return JsxRuntime.jsx(ProductCreateReservation$ProductCreateReservation, {
              product: ColocationProduct.fromJs(props$1.product),
              reservation: Belt_Option.map(Caml_option.nullable_to_opt(props$1.reservation), Reservation.Draft.fromJs),
              userLoginStatus: User.LoginStatus.fromBool(props.context.isUserSignedIn)
            });
}

var $$default = ProductCreateReservation$default;

export {
  ProductCreateReservation ,
  $$default as default,
}
/* css Not a pure module */
